import React, { Fragment } from 'react';

const TeachersAidProgramAgreement = () => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <p>
        This document is an agreement made between Houston Food Bank’s Teachers Aid and your school,
        that by participating in the school supply program, the school agrees to the following:
      </p>
      <div className="agreement-div">
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.</span>
                School must at least be enrolled in the Houston Food Bank’s Backpack
                Buddy or School Market Program
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.</span>
               60% or more of students at the school are eligible for free/reduced lunch
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.</span>
                Site Coordinator has been assigned and agrees to:
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">a.</span>
                Assist in registering staff on Booxi and assist with ordering supplies
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">b.</span>
                Be able to answer staff questions and inquiries
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">c.</span>
                Be responsible for keeping your teacher roster updated after its
                initial application submission
            </p>
          </li>
          {/* <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
                Make sure staff is aware to bring their own shopping bags and
                current year School ID for entry
            </p>
          </li> */}
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
                Inform staff that only Principals, Assistant Principals, Counselors,
                CIS representatives, and Teachers can participate (Substitute Teachers,
                Aids, Paraprofessionals, Cafeteria and Janitorial Staff are not
                able to participate)
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">f.</span>
                Consent to Client Data Privacy Statement
            </p>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default TeachersAidProgramAgreement;
