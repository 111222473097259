import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  required,
  requiredTrue,
  email,
  signeeEmailmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import ProgramAgreement from '../ProgramAgreement';

const BackpackBuddySignatureContent = ({
  isAdmin,
  school,
  signee,
  isApproved,
}) => (
  <>
    <div className="bb-title">
      <h2 className="purple-headline half-width signature">
        <strong>Backpack Buddy Program Agreement Acknowledgement</strong>
      </h2>
      <div className="agreement-site-info">
        <div className="site-info-line">
          <div className="site-info">School Name:</div>
          <div className="site-info-input">{school.name}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">School District:</div>
          <div className="site-info-input">{school.districtName}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 1:</div>
          <div className="site-info-input">{school.address1}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 2:</div>
          <div className="site-info-input">{school.address2}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">City:</div>
          <div className="site-info-input">{school.city}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">County:</div>
          <div className="site-info-input">{school.county}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">State:</div>
          <div className="site-info-input">{school.state}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Postal Code:</div>
          <div className="site-info-input">{school.zip}</div>
        </div>
      </div>
    </div>
    <Field
      component={ProgramAgreement}
      name="readProgramAgreement"
      validate={requiredTrue}
    />
    <div className="agreement-confirm">
      <p>
        <strong>
          I understand that the School Partner will receive food from the HFB to help provide food
          to chronically hungry children over the weekend. I understand that the food we
          receive for the Backpack Buddy program can only be used for that purpose.
          I understand that the food and backpacks cannot be sold, used for other school programs,
          used for fundraisers, given to staff, or used for any other purpose other than to provide
          food to chronically hungry children. Food sack items may not be removed, replaced
          or added unless approved by the HFB. Failure to comply with the program requirements will
          result in the loss of the Backpack Buddy program at School Partner.
        </strong>
      </p>
      <div className="spacer-15" />
      <Field
        name="signeeAgreeToProgramPurpose"
        validate={requiredTrue}
        component={Checkbox}
        disabled={isApproved || isAdmin}
        className="checkbox-inline w-checkbox"
        label="I understand the above"
      />
    </div>
    <p>
      <strong>
        I have read the Backpack Buddy Information Packet in its entirety.
        I, the School Partner Site Coordinator and/or the affiliates
        at the campus level, acknowledge the respective duties and responsibilities related to
        the administration of the Backpack Buddy Program.
      </strong>
    </p>
    <div className="spacer-15" />
    <Field
      name="signeeAgreeToProgramResponsibilities"
      validate={requiredTrue}
      component={Checkbox}
      disabled={isApproved || isAdmin}
      className="checkbox-inline w-checkbox"
      label="I understand the above"
    />
    <h4 className="purple-headline boxed-in">Principal Acknowledgement</h4>
    <p>
      Type your name and email as your acknowledgement.
    </p>
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Name
                  </label>
                  <i>{signee.fullName}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={required}
          disabled={isApproved || isAdmin}
          name="signeeName"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Email
                  </label>
                  <i>{signee.email}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={[required, email, signeeEmailmatch]}
          disabled={isApproved || isAdmin}
          name="signeeEmail"
          className="text-field w-input"
        />
      </div>

    </div>
  </>
);

BackpackBuddySignatureContent.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  signee: PropTypes.object.isRequired,
};

export default BackpackBuddySignatureContent;
