/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const ProgramAgreement = ({
  input,
  meta: { touched, error },
}) => {
  const handleContainerOnBottom = useCallback(() => {
    if (!input.value) {
      input.onChange(true);
    }
  }, [input]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom, 50);

  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <p>
        This document is an agreement made between the Houston Food Bank (hereinafter referred
        to as “HFB”) located at 535 Portwall St. Houston, TX 77029 and your school (hereinafter
        referred to as “School Partner”).
      </p>
      <div ref={containerRef} className="agreement-div scroll">
        <h5 className="black-headline">The School Partner agrees to: </h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.</span>
              Ensures the School Partner staff complies with all applicable local, state,
              and federal statutes, ordinances and regulations including, but not limited
              to, the safe and proper handling of food and distribution of food.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.</span>
              Willingness to abide by the policies, procedures, and record keeping requirements
              of HFB.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.</span>
              School Partner staff and volunteers will not engage in discrimination, in the
              provision of services, against any person because of race, color, citizenship,
              religion, gender, national origin, ancestry, age, marital status, disability,
              sexual orientation including gender identity or expression, unfavorable discharge
              from the military, or status as a protected veteran.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">4.</span>
              Ensure that all student participants have a signed permission slip. on Claromentis. All
              Backpack Buddy records should be stored at the School Partner location for a minimum
              of three years.
            </p>
            <p>
              *Scanning permission slips to the Backpack Buddy staff will not be accepted.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">5.</span>
              Distribute healthy, nutritious Backpack Buddy food sacks to children free of charge.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">6.</span>
              Identify chronically hungry children in your school(s) using the guidelines specified in the
              Student Referral Form, as indicated in the Backpack Buddy Informational Packet.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">7.</span>
              Participating children must meet income eligibility with either receiving free or
              reduced lunch at school or have a letter of extreme circumstances or economic
              hardship(s) written by parent or School Partner Coordinator.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">8.</span>
              Identify any food allergies that the enrolled child may have (peanuts, milk, etc.).
              &nbsp;Flag their name so that those items will not be distributed to them once sent
              to the school. &nbsp;Contact the Backpack Buddy Manager for specific food allergy
              procedures.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">9.</span>
              Distribute food sacks each week (on Friday’s, unless otherwise specified due
              to holidays or school closures) to children with completed permission forms on file.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">10.</span>
              Provide food sacks a minimum of three (3) times a month while the program is in
              operation.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">11.</span>
              Store the food sacks six (6) inches off of the floor, six (6) inches away
              from the wall, and six (6) inches below the ceiling.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">12.</span>
              The food should be kept in a secure place that is sanitary,
              temperature controlled, and away from cleaning materials and toxic chemicals.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">13.</span>
              Keep accurate records. &nbsp;Prepare, submit, and adhere to the electronic
              monthly report system operated by HFB each month by the 1st of the next
              month.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">14.</span>
              Please have the School Partner Coordinator available for an on-site
              monitoring visit at least once every year from HFB staff or HFB certified
              volunteer.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">15.</span>
              Ensure School Partner staff and volunteers with direct repetitive contact with
              children pass a national background check, as indicated in the Backpack Buddy
              Informational Packet.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">16.</span>
              At least one School Partner representative will need to receive yearly food
              safety training. &nbsp;HFB will provide the training to the School Partner
              Site Coordinator via an online tool. &nbsp;If the trained person leaves the
              school, please notify HFB to schedule a new training.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">17.</span>
              Program agreement to be submitted yearly by the School Partner with updated contact
              information and allotment numbers to continue the program.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">18.</span>
              If School Partner is contacted by any form of media to discuss the Backpack Buddy
              program, please get in touch with the Backpack Buddy staff before granting any
              interviews.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">19.</span>
              Notify Backpack Buddy staff immediately if the School Partner Site Coordinator’s contact
              information changes during the school year.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">20.</span>
              For any questions that you may have about the program, please contact the Backpack
              Buddy staff.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">21.</span>
              Display the “And Justice for All” poster in the food distribution location (i.e., the School
              Partner Site Coordinator’s office or nurse’s station).
            </p>
          </li>
        </ul>
      </div>
      <div className="spacer-15" />
      <p class-name="agreement-bottom-padding">
        <span className="agreement-bold">Co-branding</span> as it pertains to this agreement is defined as the imagery,
        verbiage, and any type of communication and visual representation of the
        Campaign/Partnership between the Organization and the Partner.
      </p>
      <div className="spacer-15" />
      <p className="agreement-indent">
        <strong>
          <span className="agreement-bold-underline">Agreement</span>
        </strong>
      </p>
      <p className="agreement-indent">
          This Campaign/Partnership agreement between the two following parties; Organization,
          ______________________ and the Parnter _____________________ to co-brand/recognize
          Houston Food Bank on events where the Houston Food Bank provides majority of the
          product and services.
      </p>
      <div className="spacer-15" />
      <p className="agreement-indent">
        <strong>
          <span className="agreement-bold-underline">Definitions</span>
        </strong>
      </p>
      <p className="agreement-indent">
          Co-brand means Partner/Organization agrees to incorporate the graphical image file
          and/or trade name or mark approved verbiage and/or logo on social media, signage,
          and/or media mentions on events where the Houston Food Bank provides majority of
          the product and services.
      </p>
      <div className="spacer-15" />
      {touched && error
        && (
          <span
            style={{
              color: 'red',
            }}
          >
            You must read the above Agreement
          </span>
        )
      }
    </Fragment>
  );
};

ProgramAgreement.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ProgramAgreement;
