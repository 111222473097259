import gql from 'graphql-tag';

export default gql`
  mutation saveTeachersAidApplicationStepTwo($input: ApplicationTeachersAidStepTwoInput!) {
    saveTeachersAidApplicationStepTwo(input: $input) {
      applicationTeachersAidId
      certififedVerification
      school {
        teachers {
          teacherId
          firstName
          lastName
          title
          phone
          email
          gradesTaught
          subjectsTaught
          numStudentsTaughtPerYear
        }
      }  
    }
  }
`;
