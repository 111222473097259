/* eslint-disable */

// export const GRAPHQL_URI = String(process.env.REACT_APP_GRAPHQL_URI) || "http://localhost:4000/graphql";
export const GRAPHQL_URI = String(process.env.REACT_APP_GRAPHQL_URI) || "https://gql-staging.houstonfoodbank.org/graphql";
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const AZURE_AUTHORITY_URL = String(process.env.REACT_APP_AZURE_AUTHORITY_URL) || "https://login.microsoftonline.com/6a2d9da9-93fa-4911-883c-f55b743c5503";
export const AZURE_CLIENT_ID = String(process.env.REACT_APP_AZURE_CLIENT_ID) || "e017208b-1be9-4d88-96f9-4dec0c145c55";
export const AWS_S3_DOCUMENT_BUCKET = String(process.env.REACT_APP_AWS_S3_DOCUMENT_BUCKET) || "hfb-backpack-buddy-documents";
export const AWS_S3_RESOURCE_BUCKET = String(process.env.REACT_APP_AWS_S3_RESOURCE_BUCKET) || "hfb-backpack-buddy-resources";
export const PAGE_SIZE = process.env.PAGE_SIZE || 25;
export const DEFAULT_TERM_YEAR = String(process.env.REACT_APP_DEFAULT_TERM_YEAR) || "2022";
