import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  pullAt,
  has,
} from 'lodash';
import { Field } from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import Multiselect from 'react-widgets/lib/Multiselect';
import TextField from '../TeamMemberTextField';
import CheckboxGroup from '../CheckboxGroup';
import deleteIcon from '../../images/delete.svg';
import {
  email,
  phone,
  teacherInputRequired,
  parseNumber,
  normalizePhone,
} from '../../helpers';
import generateUniqueKey from '../../helpers/generateUniqueKey';
import sortGradesServed from '../../helpers/sortGradesServed';

const Teachers = ({
  input: teachersInput,
  newTeacher,
  change,
  meta: { touched, error },
  formSyncErrors,
  disabled,
  gradesServed,
  subjects,
}) => {
  const renderMultiselect = ({ input, data }) => (
    <Multiselect
      {...input.name}
      value={input.value || []}
      data={data}
      valueField="value"
      textField="label"
      onChange={(e) => {
        if (!e.length) {
          input.onChange('');
        } else {
          input.onChange(e.map(({ value }) => value));
        }
      }}
      placeholder="Select subjects"
      className="multi-select-box"
      containerClassName="teacher-subject-dropdown"
    />
  );
  return (
    <Fragment>
      {
        !disabled && (
          <Fragment>
            <div className="field-combo-wrapper">
              <div className="field-combo thirds">
                <Field
                  label={<label htmlFor="First" className="field-label">Teacher First Name</label>}
                  component={TextField}
                  validate={teacherInputRequired}
                  name="newTeacher.firstName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
              <div className="field-combo thirds">
                <Field
                  label={<label htmlFor="Last" className="field-label">Teacher Last Name</label>}
                  component={TextField}
                  validate={teacherInputRequired}
                  name="newTeacher.lastName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
              <div className="field-combo thirds">
                <Field
                  label={<label htmlFor="Last-3" className="field-label">Job Title (optional)</label>}
                  component={TextField}
                  name="newTeacher.title"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            <div className="field-combo-wrapper">
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Phone</label>}
                  component={TextField}
                  validate={phone}
                  normalize={normalizePhone}
                  name="newTeacher.phone"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="Last-3" className="field-label">Email</label>}
                  component={TextField}
                  validate={[teacherInputRequired, email]}
                  placeholder="e.g. educator@school.com"
                  name="newTeacher.email"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            <div className="checklist-question">
              <label htmlFor="First-3" className="field-label">Grades Taught (check all that apply)</label>
              <Field
                name="newTeacher.gradesTaught"
                options={sortGradesServed(gradesServed)}
                component={CheckboxGroup}
                className="checkbox-inline w-checkbox _7"
              />
            </div>
            <div className="field-combo-wrapper dropdown-select">
              <div className="field-combo">
                <label htmlFor="First-3" className="field-label">Subjects Taught</label>
                <Field
                  name="newTeacher.subjectsTaught"
                  component={renderMultiselect}
                  data={subjects.options}
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="Last-3" className="field-label">Number of Students Taught Per Year</label>}
                  component={TextField}
                  parse={parseNumber}
                  maxLength={4}
                  name="newTeacher.numStudentsTaughtPerYear"
                  className="text-field w-input"
                />
              </div>
            </div>
            <br />
            <div
              className="submit-button w-button"
              onClick={() => {
                if (newTeacher && !has(formSyncErrors, 'newTeacher')) {
                  teachersInput.value.push(newTeacher);
                  change('school.teachers', teachersInput.value);
                  change('newTeacher', null);
                }
              }}
            >
              Add Teacher
            </div>
          </Fragment>
        )
      }
      <div className="roster-table">
        <div className="roster-table-headers">
          <div className="column teachers">
            <h4 className="roster-table-header">First Name</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Last Name</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Job Title</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Phone</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Email</h4>
          </div>
        </div>
        <div className="roster-content">
          {
            teachersInput.value && teachersInput.value.map((teacher, i) => {
              const {
                firstName,
                lastName,
                title,
                phone: teacherPhone,
                email: teacherEmail,
              } = teacher;
              return (
                <div className="row" key={generateUniqueKey(firstName)}>
                  <div className="column teachers">
                    <div className="padding-left-ten">{firstName}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{lastName}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{title || 'N/A'}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{teacherPhone || 'N/A'}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{teacherEmail || 'N/A'}</div>
                  </div>
                  <div className="column teachers delete icon">
                    {
                      !disabled && (
                        <div
                          className="crud-action-icon-wrapper w-inline-block"
                          onClick={() => {
                            confirmAlert({
                              message: 'Are you sure?',
                              buttons: [
                                {
                                  label: 'Cancel',
                                },
                                {
                                  label: 'Delete',
                                  onClick: () => {
                                    const newValue = [...teachersInput.value];
                                    pullAt(newValue, i);
                                    teachersInput.onChange(newValue);
                                  },
                                },
                              ],
                            });
                          }}
                        >
                          <img src={deleteIcon} width={14} alt="delete" />
                        </div>
                      )
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      {touched && error
        && (
          <span style={{
            fontSize: 12, color: 'red',
          }}
          >
            {error}
          </span>
        )
      }
    </Fragment>
  );
};

Teachers.propTypes = {
  input: PropTypes.object.isRequired,
  newTeacher: PropTypes.object,
  meta: PropTypes.object.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  gradesServed: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
};

Teachers.defaultProps = {
  newTeacher: null,
  disabled: false,
};

export default Teachers;
