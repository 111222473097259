import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { get } from 'lodash';
import {
  requiredTrue,
  required,
  email,
  teamPrincipalmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import ApplicationActions from '../ApplicationActions';
import TeachersAidProgramAgreement from '../TeachersAidProgramAgreement';

const ApplicationTeachersAidStepThree = (props) => {
  const {
    save,
    handleSubmit,
    readOnly,
    termsAvailable,
    initialValues,
    invalid,
    submitFailed,
  } = props;
  const principal = get(initialValues, 'school.principal');
  const principalEmail = get(principal, 'email', 'N/A');

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="teachersaid-form">
      <div className="agreement">
        <TeachersAidProgramAgreement />
        <Field
          label={<strong>I certify that all information provided is correct and that I have read and understand the rules and commitments of the Teachers Aid Program</strong>}
          name="certifiedProgramRules"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline w-checkbox margin-top-30"
          disabled={readOnly || !termsAvailable}
        />
        {
          !readOnly && termsAvailable && (
            <Fragment>
              <h4 className="purple-headline boxed-in">Principal Signature</h4>
              <p>
                  Your Principal is required to provide an e-signature for this application to be
                  considered. An email message will be sent to the Principal email that you provided.
                  Your Principal must click the link and follow the instructions to provide
                  their signature.
              </p>
              <div className="field-combo-wrapper sign-up">
                <div className="field-combo">
                  <Field
                    label={
                        (
                          <Fragment>
                            <label htmlFor="Last" className="field-label">
                              Verify Principal Email
                            </label>
                            <i>{principalEmail}</i>
                          </Fragment>
                        )
                      }
                    component={TextField}
                    placeholder="Enter Principal Email"
                    validate={[required, email, teamPrincipalmatch]}
                    name="verifyPrincipalEmail"
                    className="text-field w-input"
                  />
                </div>
              </div>
            </Fragment>
          )
        }
      </div>
      <ApplicationActions {...props} />
      <br />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

ApplicationTeachersAidStepThree.propTypes = {
  adminView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
};

ApplicationTeachersAidStepThree.defaultProps = {
  readOnly: false,
};

export default ApplicationTeachersAidStepThree;
