/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  required,
  requiredTrue,
  email,
  signeeEmailmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import TeachersAidProgramAgreement from '../TeachersAidProgramAgreement';

const TeachersAidSignatureContent = ({
  isAdmin,
  school,
  signee,
  isApproved,
}) => (
  <>
    <div className="bb-title">
      <h2 className="purple-headline half-width signature">
        <strong>Teachers Aid Program Agreement Acknowledgement</strong>
      </h2>
      <div className="agreement-site-info">
        <div className="site-info-line">
          <div className="site-info">School Name:</div>
          <div className="site-info-input">{school.name}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">School District:</div>
          <div className="site-info-input">{school.districtName}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 1:</div>
          <div className="site-info-input">{school.address1}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 2:</div>
          <div className="site-info-input">{school.address2}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">City:</div>
          <div className="site-info-input">{school.city}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">County:</div>
          <div className="site-info-input">{school.county}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">State:</div>
          <div className="site-info-input">{school.state}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Postal Code:</div>
          <div className="site-info-input">{school.zip}</div>
        </div>
      </div>
    </div>
    <TeachersAidProgramAgreement />
    <div className="agreement-confirm">
      <p>
        <strong>
          I understand that the supplies we receive from the Teachers Aid Program
          can only be used for the students. I understand that the food cannot be sold,
          used for other school programs, used for fundraisers, or used for any other
          purpose other than to provide for the students. Failure to comply with the
          program requirements will result in the loss of the Teachers Aid program
          at my school.
        </strong>
      </p>
      <div className="spacer-15" />
      <Field
        name="signeeAgreeToProgramPurpose"
        validate={requiredTrue}
        component={Checkbox}
        disabled={isApproved || isAdmin}
        className="checkbox-inline w-checkbox"
        label="I understand the above"
      />
    </div>
    <p>
      <strong>
        I, the School Partner Site Coordinator and/or the affiliates at the campus level,
        acknowledge the respective duties and responsibilities related to the
        administration of the Teachers Aid Program.
      </strong>
    </p>
    <div className="spacer-15" />
    <Field
      name="signeeAgreeToProgramResponsibilities"
      validate={requiredTrue}
      component={Checkbox}
      disabled={isApproved || isAdmin}
      className="checkbox-inline w-checkbox"
      label="I understand the above"
    />
    <div className="spacer-15" />
    <p class-name="agreement-bottom-padding">
      <span className="agreement-bold">Co-branding</span> as it pertains to this agreement is defined as the imagery,
      verbiage, and any type of communication and visual representation of the
      Campaign/Partnership between the Organization and the Partner.
    </p>
    <div className="spacer-15" />
    <p className="agreement-indent">
      <strong>
        <span className="agreement-bold-underline">Agreement</span>
      </strong>
    </p>
    <p className="agreement-indent">
        This Campaign/Partnership agreement between the two following parties; Organization,
        ______________________ and the Parnter _____________________ to co-brand/recognize
        Houston Food Bank on events where the Houston Food Bank provides majority of the
        product and services.
    </p>
    <div className="spacer-15" />
    <p className="agreement-indent">
      <strong>
        <span className="agreement-bold-underline">Definitions</span>
      </strong>
    </p>
    <p className="agreement-indent">
        Co-brand means Partner/Organization agrees to incorporate the graphical image file
        and/or trade name or mark approved verbiage and/or logo on social media, signage,
        and/or media mentions on events where the Houston Food Bank provides majority of
        the product and services.
    </p>
    <div className="spacer-15" />
    <p>
      Type your name and email as your acknowledgement.
    </p>
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Name
                  </label>
                  <i>{signee.fullName}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={required}
          disabled={isApproved || isAdmin}
          name="signeeName"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Email
                  </label>
                  <i>{signee.email}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={[required, email, signeeEmailmatch]}
          disabled={isApproved || isAdmin}
          name="signeeEmail"
          className="text-field w-input"
        />
      </div>

    </div>
  </>
);

TeachersAidSignatureContent.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  signee: PropTypes.object.isRequired,
};

export default TeachersAidSignatureContent;
