import gql from 'graphql-tag';

export default gql`
  mutation saveApplicationStepThree($input: ApplicationStepThreeInput!) {
    saveApplicationStepThree(input: $input) {
      applicationBackpackBuddyId
      agreeToProgramPurpose
      agreeToProgramResponsibilities
    }
  }
`;
