import gql from 'graphql-tag';

export default gql`
  mutation saveTeachersAidApplicationStepOne($input: ApplicationTeachersAidStepOneInput!) {
    saveTeachersAidApplicationStepOne(input: $input) {
      applicationTeachersAidId
      new
      school {
        name
        type
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        teachersAidSchoolNumber
        teamMembers {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        teachers {
          teacherId
          firstName
          lastName
          title
          phone
          email
          gradesTaught
          subjectsTaught
          numStudentsTaughtPerYear
        }
        siteCoordinator {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        principal {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }    
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      demographicAsian
      demographicBlack
      demographicHispanic
      demographicWhite
    }
  }
`;
