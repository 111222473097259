import gql from 'graphql-tag';

export default gql`
  query schoolsTeachersAid($termId: ID) {
    schoolsTeachersAid(termId: $termId) {
      schoolId
      teachersAidSchoolNumber
      name
      address1
      address2
      city
      state
      zip
      county
      districtId
      districtName
      type
      gradesServed
      statusTeachersAid
      deletedAt
      teamMembersTeachersAid {
        teamMemberId
        fullName
        email
        type
      }
      applicationsTeachersAid {
        applicationTeachersAidId
        new
        termFormattedShort
        submittedAt
        status
        certififedVerification
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
        demographicAsian
        demographicBlack
        demographicHispanic
        demographicWhite
        certifiedProgramRules
        waitlisted
        waitlistedAt
        createdAt
      }
    }
  }
`;
