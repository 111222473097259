const kidsCafeServiceInterests = [
  {
    name: 'interestBreakfast',
    label: 'Breakfast Service (06:00 am – 09:00 am)',
  },
  {
    name: 'interestBrunch',
    label: 'Brunch Service (10:00 am – 11:00 am)',
  },
  {
    name: 'interestLunch',
    label: 'Lunch Service  (11:00 am – 2:00 pm)',
  },
  {
    name: 'interestBogoLunch',
    label: 'BOGO Lunch Service (2:00 pm – 4:00 pm; receive a free Adult meal for every child served)',
  },
  {
    name: 'interestSnack',
    label: 'Snack Service (Anytime)',
  },
  {
    name: 'interestSupper',
    label: 'Supper Service (02:00 pm – 06:00 pm)',
  },
];

export default kidsCafeServiceInterests;
