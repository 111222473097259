import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form, Field } from 'redux-form';
import {
  required,
  email,
  teamPrincipalmatch,
  requiredTrue,
} from '../../helpers';
import ApplicationActions from '../ApplicationActions';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import ProgramAgreement from '../ProgramAgreement';

const StepThreeForm = (props) => {
  const {
    handleSubmit,
    save,
    adminView,
    readOnly,
    termsAvailable,
    initialValues,
    invalid,
    submitFailed,
  } = props;
  const principal = get(initialValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'PRINCIPAL');
  const principalEmail = get(principal, 'email', 'N/A');

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="backbackbuddy-form">
      <Field
        component={ProgramAgreement}
        name="readProgramAgreement"
        validate={requiredTrue}
      />
      <div className="agreement-confirm">
        <p>
          <strong>
            I understand that the School Partner will receive food from the HFB to help provide food
            to chronically hungry children over the weekend. I understand that the food we
            receive for the Backpack Buddy program can only be used for that purpose.
            I understand that the food and backpacks cannot be sold, used for other school programs,
            used for fundraisers, given to staff, or used for any other purpose other than to
            provide food to chronically hungry children. Food sack items may not be removed,
            replaced or added unless approved by the HFB. Failure to comply with the program
            requirements will result in the loss of the Backpack Buddy program at School Partner.
          </strong>
        </p>
        <div className="spacer-15" />
        <Field
          name="agreeToProgramPurpose"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline w-checkbox"
          label="I understand the above"
          disabled={readOnly || !termsAvailable}
        />
      </div>
      <p>
        <strong>
          I certify that I have read the Backpack Buddy Information Packet in its
          entirety. By signing this agreement, I, the School Partner Site Coordinator
          and/or the affiliates at the campus level, acknowledge the respective duties
          and responsibilities related to the administration of the Backpack Buddy Program.
        </strong>
      </p>
      <div className="spacer-15" />
      <Field
        name="agreeToProgramResponsibilities"
        validate={requiredTrue}
        component={Checkbox}
        className="checkbox-inline w-checkbox"
        label="I understand the above"
        disabled={readOnly || !termsAvailable}
      />
      {
        !adminView && !readOnly && termsAvailable && (
          <Fragment>
            <div className="principal">
              <h4 className="purple-headline boxed-in">Principal Signature</h4>
              <p>
                Your Principal is required to provide an e-signature for this application to be
                considered. An email message will be sent to the Principal email that you provided.
                Your Principal must click the link and follow the instructions to provide
                their signature.
              </p>
              <div className="field-combo">
                <Field
                  label={
                    (
                      <Fragment>
                        <label htmlFor="First-3" className="field-label">
                          Verify Principal Email
                        </label>
                        <i>{principalEmail}</i>
                      </Fragment>
                    )
                  }
                  component={TextField}
                  placeholder="Enter Principal Email"
                  validate={[required, email, teamPrincipalmatch]}
                  name="verifyPrincipalEmail"
                  className="text-field w-input"
                />
              </div>
            </div>
          </Fragment>
        )
      }
      <ApplicationActions {...props} />
      <br />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

StepThreeForm.propTypes = {
  adminView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

StepThreeForm.defaultProps = {
  readOnly: false,
};

export default StepThreeForm;
