import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import {
  required,
  phone,
  email,
  applicationTerm,
  parseNumber,
  normalizePhone,
  validateEnrollmentNumbers,
  validateSchoolMarketSchoolType,
  uniqueSchoolMarketTeamMember,
} from '../../helpers';
import getLabel from '../../helpers/getLabel';
import TextField from '../TextField';
import RadioButtonGroup from '../RadioButtonGroup';
import CheckboxGroup from '../CheckboxGroup';
import SchoolSelectInput from '../SchoolSelectInput';
import { allSchoolsQuery } from '../../apollo';
import DropDownMenu from '../DropDownMenu';
import ApplicationActions from '../ApplicationActions';
import sortGradesServed from '../../helpers/sortGradesServed';
import BackgroundVerification from '../BackgroundVerification';
import DistrictDropDownMenu from '../DistrictDropDownMenu';

const ApplicationSchoolMarketStepOne = (props) => {
  const {
    data,
    submit,
    handleSubmit,
    change,
    readOnly,
    termYear,
    termsAvailable,
    configSchoolMarket,
    termsForYear,
    invalid,
    submitFailed,
    enums: {
      gradesServed,
      schoolTypes,
      operatingFrequencies,
      preferredDistributionDays,
      preferredDistributionTimes,
      schoolMarketTypes,
      termTypes,
    },
  } = props;

  const { schoolEnabled, summerEnabled } = configSchoolMarket.configSchoolMarket;

  const termOptions = termsForYear.map(({ termId, type, termFormatted }) => ({
    value: termId,
    label: (
      <span>
        {getLabel(termTypes.options, type)}
        <span className="term-text">{termFormatted}</span>
      </span>
    ),
  }));

  const dynamicOperatingFrequencies = operatingFrequencies.options.map(({ value, label }) => {
    const withFirstYear = label.replace(' -', `, ${termYear} -`);
    const withSecondYear = withFirstYear.replace(' (', `, ${Number(termYear) + 1} (`);

    return {
      value,
      label: withSecondYear,
    };
  });

  return (
    <Form onSubmit={handleSubmit(submit)} id="email-form" className="program-form">
      <h4 className="purple-headline boxed-in">Application Type</h4>
      {
        !termsForYear.length ? (
          <span style={{ color: 'red' }}>
            We are not currently accepting applications for this year.
          </span>
        ) : (
          <Field
            name="termId"
            options={termOptions}
            component={RadioButtonGroup}
            className="radio-field w-radio"
            disabled={readOnly || !termsAvailable}
            validate={value => applicationTerm(value, termsForYear, summerEnabled, schoolEnabled)}
          />
        )
      }
      {(!(data && data.applicationSchoolMarket && (data.applicationSchoolMarket.status === 'APPROVED')) && !termsAvailable && Boolean(termsForYear.length)) && (
        <span style={{ color: 'red', display: 'block' }}>
          We are not currently accepting new applications.
        </span>
      )}
      <div className="site-information">
        <h4 className="purple-headline boxed-in">School Information</h4>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">Did your school participate in this program last year?</label>
          <Field
            name="new"
            options={[
              {
                value: false,
                label: 'Yes',
              },
              {
                value: true,
                label: 'No',
              },
            ]}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            parse={val => val === 'true'}
            disabled={readOnly || !termsAvailable}
            validate={required}
          />
        </div>
        <label htmlFor="First" className="field-label">School Type (School Market is not available for Elementary Schools)</label>
        <Field
          name="school.type"
          validate={[required, validateSchoolMarketSchoolType]}
          options={schoolTypes.options}
          component={RadioButtonGroup}
          className="radio-field inline-radio w-radio"
          disabled={readOnly || !termsAvailable}
        />
        <div className="field-combo-wrapper dropdown-select">
          <div className="field-combo">
            <Query query={allSchoolsQuery} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                const schoolOptions = get(data, 'allSchools', []).map(school => ({
                  school,
                  label: school.name,
                }));
                return (
                  <Field
                    label={<label htmlFor="First" className="field-label">School Name</label>}
                    component={SchoolSelectInput}
                    options={schoolOptions}
                    validate={required}
                    change={change}
                    loading={loading}
                    name="school.name"
                    placeholder={error ? 'Error loading schools, please refresh.' : 'Enter School Name'}
                    className="text-field w-input"
                    disabled={readOnly || !termsAvailable}
                  />
                );
              }}
            </Query>
          </div>
          <div className="field-combo">
            <Field
              name="school.districtId"
              className="text-field w-select"
              component={DistrictDropDownMenu}
              label={<label htmlFor="First-3" className="field-label">School District</label>}
              validate={required}
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
              component={TextField}
              validate={required}
              name="school.address1"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Address Line 2 (optional)</label>}
              component={TextField}
              name="school.address2"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">City</label>}
              component={TextField}
              validate={required}
              name="school.city"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">County</label>}
              component={TextField}
              validate={required}
              name="school.county"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">State</label>}
              component={TextField}
              validate={required}
              name="school.state"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Zip</label>}
              component={TextField}
              validate={required}
              name="school.zip"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="checklist-question">
          <label htmlFor="First-3" className="field-label">Grades Served (check all that apply)</label>
          <Field
            name="school.gradesServed"
            validate={required}
            options={sortGradesServed(gradesServed)}
            component={CheckboxGroup}
            className="checkbox-inline w-checkbox _7"
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">Total Enrollment</label>}
              component={TextField}
              validate={required}
              name="numTotalEnrollment"
              className="text-field w-input"
              parse={parseNumber}
              maxLength={4}
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Number of Students Receiving Reduced Lunch</label>}
              component={TextField}
              validate={[required, validateEnrollmentNumbers]}
              name="numReducedLunch"
              className="text-field w-input"
              parse={parseNumber}
              maxLength={4}
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Number of Students Receiving Free Lunch</label>}
              component={TextField}
              validate={[required, validateEnrollmentNumbers]}
              name="numFreeLunch"
              className="text-field w-input"
              parse={parseNumber}
              maxLength={4}
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            name="marketType"
            className="select-field w-select"
            component={DropDownMenu}
            label={(
              <>
                <label htmlFor="Last-3" className="field-label" style={{ marginTop: 0 }}>Your Market Type</label>
                <p className="market-type-disclaimer">
                *Traditional distribution models have
                been modified to ensure social distancing
                and follow safety protocols to protect
                our clients, staff, partners, and volunteers
                </p>
              </>
            )}
            placeholder="Select Type"
            options={schoolMarketTypes.options}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            name="operatingFrequency"
            className="select-field w-select"
            component={DropDownMenu}
            label={<label htmlFor="Last-3" className="field-label">Your Market Distributions Frequency</label>}
            placeholder="Select Frequency"
            options={dynamicOperatingFrequencies}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            name="preferredDistributionDay"
            className="select-field w-select"
            component={DropDownMenu}
            label={<label htmlFor="Last-3" className="field-label">Preferred Market Distribution Day (subject to availability)</label>}
            placeholder="Select Day"
            options={preferredDistributionDays.options}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            name="preferredDistributionTime"
            className="select-field w-select"
            component={DropDownMenu}
            label={<label htmlFor="Last-3" className="field-label">Preferred Market Distribution Time</label>}
            placeholder="Select Time"
            options={preferredDistributionTimes.options}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            name="secondaryPreferredDistributionDay"
            className="select-field w-select"
            component={DropDownMenu}
            label={<label htmlFor="Last-3" className="field-label">Secondary Preferred Market Distribution Day (subject to availability)</label>}
            placeholder="Select Day"
            options={preferredDistributionDays.options}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            name="secondaryPreferredDistributionTime"
            className="select-field w-select"
            component={DropDownMenu}
            label={<label htmlFor="Last-3" className="field-label">Secondary Preferred Market Distribution Time</label>}
            placeholder="Select Time"
            options={preferredDistributionTimes.options}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="point-of-contact">
        <h4 className="purple-headline boxed-in">Primary Point of Contact</h4>
        <p>
          Houston ISD Schools: The Primary Point of Contact
          (school liaison) is required to provide an e-signature
          for this application to be considered. An email message
          will be sent to the Primary Point of Contact email that
          is provided. The Primary Point of Contact must
          click the signature link in the email and
          follow the instructions to provide their signature.
        </p>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="school.primaryPointOfContact.firstName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="school.primaryPointOfContact.lastName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Job Title (optional)</label>}
              component={TextField}
              name="school.primaryPointOfContact.title"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="school.primaryPointOfContact.phone"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueSchoolMarketTeamMember]}
              name="school.primaryPointOfContact.email"
              className="text-field w-input"
              placeholder="e.g. educator@school.com"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
      </div>
      <div className="coordinator">
        <h4 className="purple-headline boxed-in">Principal</h4>
        <p>
          Non-Houston ISD Schools: The Principal is required to provide an
          e-signature for this application to be considered. An email
          message will be sent to the Principal email that is provided.
          The Principal must click the signature link in the email and
          follow the instructions to provide their signature.
        </p>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="school.principal.firstName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="school.principal.lastName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="school.principal.phone"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueSchoolMarketTeamMember]}
              name="school.principal.email"
              className="text-field w-input"
              placeholder="e.g. educator@school.com"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
      </div>
      <BackgroundVerification {...props} />
      <div className="next-step">
        <ApplicationActions {...props} />
      </div>
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

ApplicationSchoolMarketStepOne.propTypes = {
  submit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  termYear: PropTypes.string.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
  configSchoolMarket: PropTypes.object.isRequired,
  termsForYear: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

ApplicationSchoolMarketStepOne.defaultProps = {
  readOnly: false,
  data: null,
};

export default ApplicationSchoolMarketStepOne;