import gql from 'graphql-tag';

export default gql`
  query ($applicationTeachersAidId: ID!) {
    applicationTeachersAid(applicationTeachersAidId: $applicationTeachersAidId) {
      applicationTeachersAidId
      status
      new
      termId
      term {
        id
        termId
        type
        year
        startDate
        endDate
        termFormatted
        termFormattedShort
      }
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      demographicAsian
      demographicBlack
      demographicHispanic
      demographicWhite
      certifiedProgramRules
      certififedVerification
      school {
        schoolId
        name
        type
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        teachersAidSchoolNumber
        teachers {
          teacherId
          firstName
          lastName
          title
          phone
          email
          gradesTaught
          subjectsTaught
          numStudentsTaughtPerYear
        }
        siteCoordinator {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        principal {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }
    }
  }
`;
