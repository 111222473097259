import React from 'react';
import { reduxForm, Form } from 'redux-form';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BackpackBuddySignatureContent from './BackpackBuddySignatureContent';
import TeachersAidSignatureContent from './TeachersAidSignatureContent';
import SchoolMarketSignatureContent from './SchoolMarketSignatureContent';

const SignatureForm = reduxForm({
  form: 'signatureForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})((props) => {
  const {
    isAdmin,
    isApproved,
    application,
    update,
    handleSubmit,
    submitFailed,
    invalid,
    submitSucceeded,
    submitting,
    school,
    program,
  } = props;

  const { programName } = application;
  let SignatureContent;
  switch (programName) {
    case 'backpack-buddy':
      SignatureContent = BackpackBuddySignatureContent;
      break;
    case 'teachers-aid':
      SignatureContent = TeachersAidSignatureContent;
      break;
    case 'school-market':
      SignatureContent = SchoolMarketSignatureContent;
      break;
    default:
      break;
  }

  return (
    <Form onSubmit={handleSubmit(update)}>
      {SignatureContent && <SignatureContent {...props} />}
      {
        isAdmin && program ? (
          <Link
            to={`${program.programUrl}/schools/${school.schoolId}/applications`}
            className="form-save w-button"
          >
            Exit
          </Link>
        ) : (
          <>
            {
                isApproved ? (
                  <>
                    <div style={{ color: 'red' }}>
                      Signature already submitted.
                    </div>
                    <br />
                    <Link
                      to="/login"
                      className="btn-solid-green w-button"
                    >
                      Exit
                    </Link>
                  </>
                ) : (
                  <input
                    type="submit"
                    value={submitting ? 'Please wait...' : 'Submit Signed Response'}
                    disabled={submitting || submitSucceeded}
                    className="btn-solid-green w-button"
                  />
                )
              }
            {
                (invalid && submitFailed) && (
                  <span
                    style={{
                      color: 'red',
                      lineHeight: 2.5,
                      display: 'block',
                    }}
                  >
                    You have required field(s) that are missing. Please see above.
                  </span>
                )
              }
          </>
        )
      }
    </Form>
  );
});

const mapStateToProps = (reduxState, { application, user }) => {
  const principal = get(application, 'school.principal', []);
  const primaryPointOfContact = get(application, 'school.primaryPointOfContact', []);
  const isApproved = application.status === 'APPROVED';
  const isAdmin = user ? user.type === 'ADMIN' : false;
  let signedAt = application.signedAt ? moment(application.signedAt).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
  const isHISD = application.school.districtName === 'Houston ISD';

  let signee = principal;

  if (application.programName === 'school-market' && isHISD) {
    signee = primaryPointOfContact;
  }

  if (isAdmin) {
    signedAt = application.signedAt ? moment(application.signedAt).format('MM/DD/YYYY') : '';
  }

  return {
    isAdmin,
    isHISD,
    isApproved,
    school: {
      ...application.school,
    },
    signee,
    initialValues: {
      id: application.id,
      signeeEmailToValidate: signee.email,
      signeeAgreeToProgramPurpose: isApproved,
      signeeAgreeToProgramResponsibilities: isApproved,
      signeeName: isApproved ? signee.fullName : '',
      signeeEmail: isApproved ? signee.email : '',
      signedAt,
    },
  };
};

export default connect(mapStateToProps)(SignatureForm);
