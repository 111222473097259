/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';

const SchoolMarketProgramAgreement = () => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <p>
        This document is an acknowledgement between Houston Food Bank
        (hereinafter referred to as “HFB”) located at 535 Portwall St.,
        Houston Texas 77029, and your school (hereinafter reffered to
        as "School Partner").
      </p>
      <div className="spacer-15" />
      <p>
        Mobile distributions are a one-year agreement __________
        <strong>(Initial here if choosing this model for your school)</strong>
      </p>
      <div className="spacer-15" />
      <p>
        Brick and Mortars are a two-year agreement __________
        <strong>(Initial here if choosing this model for your school)</strong>
      </p>
      <div className="agreement-div">
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">I.</span>
                Service Requirements
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">a.</span>
                Follow the safe and proper handling of food, which conforms to all local, state and federal regulations.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">b.</span>
              School Partner MUST have a minimum of 2 distributions per month. If a distribution is canceled School
              Partner MUST reschedule the missing distribution within that same month, no exceptions.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">c.</span>
                Willingness to abide by the policies, procedures, and recording keeping requirements of HFB.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
                Staff or volunteers of the program will not engage in discrimination, in the provision of service, against any person because of race, color, citizenship, religion, gender, national origin, ancestry, age, marital status, disability, sexual orientation including gender identity or expression, unfavorable discharge from the military or status as a protected veteran.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">e.</span>
                Distribute healthy, nutritious food to children free of charge.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">f.</span>
                Ensure the school and/or program complies with all applicable federal and local statutes, ordinances and regulations.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">g.</span>
                Ensure staff with direct repetitive contact with children pass a national background check. 
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">h.</span>
                At least one representative must receive training in food safety, civil rights, Link2Feed, and ordering product online.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">i.</span>
                If the school stores food overnight, school officials must be available for an on-site monitoring visit at least once every two years from Houston Food Bank.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">j.</span>
                If your site is a Brick and Mortar all food must be stored in a secure, sanitary, and temperature-controlled place away from cleaning materials and toxic chemicals.  All food must be stored 6 inches off the floor, 6 inches away from the wall, and 6 inches below the ceiling.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">k.</span>
               Distribute food to program participants in accordance with the pre-determined schedule.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">l.</span>
               Follow specific guidelines laid out by school and Houston Food Bank personnel.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">m.</span>
               Identify a School Market coordinator to be the primary contact for the School Market Program.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">n.</span>
               Receive deliveries on the designated day and time agreed upon by both parties.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">o.</span>
               Inform Houston Food Bank in writing of any changes in School Market Program personnel, days/hours of operation.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">p.</span>
               Send monthly “Student Volunteer Report” via email by the last day of every month.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">q.</span>
                Submit all “School Market Permission Slips” with parent/guardian approval (signatures), stating that their child(ren) has permission to participate in the School Market Program which includes being able to submit household information, receive food and non-food, volunteer with the public, participate in client stories, and can be photographed/videotaped.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">r.</span>
               Communicate problems and requests to HFB in a timely manner.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">II.</span>
                Houston Food Bank agrees to the following:
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">a.</span>
                Provide food a minimum of once a month while the program is in operation.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">b.</span>
                Ensure the school and/or program complies with all applicable federal and local statutes, ordinances and regulations.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">c.</span>
              HFB personnel will provide training to both the School Partner Coordinator, adult volunteers, and student volunteers in food safety, civil rights, Link2Feed, and ordering products online.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
              HFB will provide the food and program materials free of charge to the School Partner.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">e.</span>
                Conduct on site monitoring on a yearly basis to ensure all guidelines are being followed.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">f.</span>
                Schedule deliveries on the designated day and time agreed upon by both parties.
            </p>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default SchoolMarketProgramAgreement;
